$url: '../image/';
$url-fonts: '../fonts/';
$fonts-size: 16px;
$color-black: #09101d;
$color-d-blue: #1887de;
$color-l-blue: #67bffb;
$fontNL: 'NerisLight', sans-serif;
$fontNSB: 'NerisSemiBold', sans-serif;
$fontNT: 'NerisThin', sans-serif;
$fontOS: 'Open Sans', sans-serif;

@font-face {
  font-family: 'NerisLight';
  src: url($url-fonts + 'NerisLight/NerisLight.eot');
  src: url($url-fonts + 'NerisLight/NerisLight.eot?#iefix') format('embedded-opentype'), url($url-fonts + 'NerisLight/NerisLight.woff') format('woff'), url($url-fonts + 'NerisLight/NerisLight.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'NerisSemiBold';
  src: url($url-fonts + 'NerisSemiBold/NerisSemiBold.eot');
  src: url($url-fonts + 'NerisSemiBold/NerisSemiBold.eot?#iefix') format('embedded-opentype'), url($url-fonts + 'NerisSemiBold/NerisSemiBold.woff') format('woff'), url($url-fonts + 'NerisSemiBold/NerisSemiBold.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'NerisThin';
  src: url($url-fonts + 'NerisThin/NerisThin.eot');
  src: url($url-fonts + 'NerisThin/NerisThin.eot?#iefix') format('embedded-opentype'), url($url-fonts + 'NerisThin/NerisThin.woff') format('woff'), url($url-fonts + 'NerisThin/NerisThin.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

html {
  @media (min-width: 992px) and (max-width: 1200px) {

  }
  @media (min-width: 768px) and (max-width: 991px) {
    font-size: 9px;
  }
  @media (max-width: 767px) {
    font-size: 7px;
  }
}

body {
  font-family: $fontNL;
  color: $color-black;
  font-size: $fonts-size;
  line-height: 24px;
  min-width: 300px;
  overflow-x: hidden;
  input, input:focus, .btn, .btn:focus, .btn:active:focus {
    outline: none;
  }
  a {
    color: $color-d-blue;
    text-decoration: none;
    font-size: $fonts-size;
    &:hover {
      color: $color-l-blue;
    }
    &:focus {
      text-decoration: none;
      outline: none;
    }
  }
  h2 {
    font-size: 34px;
    font-family: $fontNSB;
  }
  h3 {
    font-size: 32px;
  }
  h4 {
    font-size: 30px;
  }
  h5 {
    font-size: 28px;
  }
  h6 {
    font-size: 26px;
  }
  h1 {
    text-transform: uppercase;
    text-align: center;
    margin: 25px 0 50px;
    font-size: 5.6rem;

  }
  .heading-article {
    font-size: 3.8rem;
    text-transform: none;
    text-align: left;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 35px;
  }
  .nopadding {
    padding: 0;
  }
  img {
    max-width: 100%;
  }
  #content {
    min-height: 500px;
  }
  #modal_subscribe {
    position: fixed;
    background: #fff;
    left: 30%;
    right: 30%;
    text-align: center;
    z-index: 999;
    padding: 30px 10px;
    top: 30%;
    box-shadow: 0 0 10px #ccc;
    .close-modal {
      position: absolute;
      right: 10px;
      top: 5px;
      cursor: pointer;
      &:hover {
        color: #ccc;
      }
    }
  }
  header {
    margin: 20px 0 54px 0;

  }
  nav {
    &.navbar-nav > li {
      float: left;
    }
    .col-menu {
      margin-top: 20px;
      @media (max-width: 767px) {
        margin-top: 0;
      }
    }
    li {
      & > a {
        font-family: $fontNSB;
        font-size: 14px;
        color: $color-d-blue;
        text-transform: uppercase;
        margin: 0 12px;
        padding: 13px 9px !important;
        &:active, &:focus {
          background: transparent !important;
          color: $color-black;
        }
        &.menu_active {
          color: $color-black;
          &:after {
            display: none;
          }
        }
        &:after {
          content: '';
          height: 1px;
          width: calc(100% + 10px);
          margin-left: -5px;
          margin-right: -5px;
          background: $color-d-blue;
          position: relative;
          display: block;
          margin-top: 5px;
          -webkit-transition: .2s;
          -moz-transition: .2s;
          -ms-transition: .2s;
          -o-transition: .2s;
          transition: .2s;
          @media (max-width: 767px) {
            display: none;
          }
        }

        @media (max-width: 991px) and (min-width: 768px) {
          margin: 0;
        }
      }
      &.has-child {
        & > a {
          @media (max-width: 991px) and (min-width: 768px) {
            margin-right: 10px;
          }
          &:before {
            content: '\f107';
            font-family: FontAwesome;
            position: absolute;
            right: -2px;
            font-size: 11px;
          }
          &:after {
            width: calc(100% + 20px);
            margin-right: -15px;
          }
        }
      }
      .submenu {
        position: absolute;
        opacity: 0;
        height: 0;
        background: #fff;
        -webkit-transition: .3s;
        -moz-transition: .3s;
        -ms-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        z-index: 99;
        box-shadow: 3px 3px 5px rgba(6, 6, 6, .15);
        padding: 0 10px;
        overflow: hidden;
        min-width: 135px;
        max-width: 200px;
        text-align: left;
        @media (max-width: 767px) {
          position: relative;
          opacity: 1;
          margin-left: 10px;
          height: auto;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
        }
        li {
          a {
            font-family: $fontNL;
            font-size: 16px;
            text-transform: none;
            padding: 5px 5px 10px 5px !important;
            display: block;
            position: relative;
            color: $color-black;
            &:hover {
              color: $color-d-blue;
            }
            &:after {
              display: none;
            }
          }
        }
      }
      &:hover {
        .submenu {
          opacity: 1;
          height: auto;
        }
        & > a, & a.menu_active {
          color: $color-d-blue;
          background-color: transparent !important;
          &:after {
            background: $color-l-blue;
            width: 0;
          }
        }
      }
      &:first-child a {
        margin-left: 0;
        @media (max-width: 767px) {
          margin-left: 12px;
        }
      }
      &:last-child a {
        margin-right: 0;
      }
    }
    @media (max-width: 767px) {
      &#nav-top {
        overflow: hidden;
        -webkit-transition: .3s;
        -moz-transition: .3s;
        -ms-transition: .3s;
        -o-transition: .3s;
        transition: 1s;
        position: fixed;
        z-index: 99;
        background: #fff;
        width: auto;
        max-width: 80%;
        height: 100%;
        top: 0;
        left: -100%;
        box-shadow: 2px -2px 1px #ccc;
        &.active {
          -webkit-transition: .3s;
          -moz-transition: .3s;
          -ms-transition: .3s;
          -o-transition: .3s;
          transition: 1s;
          left: 0;
        }
      }
      @media (max-width: 320px) {
        &#nav-top {
          left: -200%;
        }
      }
    }
    .nav {
      @media (max-width: 767px) {
        margin: 0;
        &.pull-right {
          float: left !important;
        }
        &.text-right {
          text-align: left !important;
        }
      }
    }
  }
  .nav-tabs {
    border-bottom: none;
    & > li {
      float: none;
      display: inline-block;
      &.active {
        & > a, & > a:hover, & > a:focus {
          background: $color-l-blue;
          color: #fff;
          border-radius: 0;
          border: 1px solid $color-l-blue;
        }
      }
      & > a {
        border: 1px solid $color-l-blue;
        border-radius: 0;
        font-family: $fontOS;
        font-weight: 600;
        font-size: 14px;
        padding: 10px 40px;
        margin: 20px;
        &:hover, &:focus {
          background: $color-l-blue;
          color: #fff;
          border-radius: 0;
          border: 1px solid $color-l-blue;
        }
        @media (max-width: 767px) {
          width: 250px;
          margin: 10px auto;
        }
      }
    }
    &.nav-form{
      border-bottom: 1px solid #c5cdd3;
      margin: 0 15px 30px 15px;
      @media (max-width: 767px) {
        margin: 0 0 30px 0;
      }
      &>li{
        float: none;
        display: inline-block;
        &.active {
          & > a, & > a:hover, & > a:focus {
            color: #09101d;
            background-color: transparent;
            border: none;
            border-bottom: 1px solid #09101d;
          }
        }
        &>a{
          font-family: $fontOS;
          font-weight: 600;
          color: $color-d-blue;
          border: none;
          border-bottom: 1px solid transparent;
          font-size: 16px;
          text-transform: uppercase;
          padding: 10px 8px;
          margin: 0 30px;
          &:hover, &:focus{
            color: #09101d;
            background-color: transparent;
            border-bottom-color: #09101d;
          }
          @media (max-width: 767px){
            width: auto;
          }
        }
        @media (max-width: 767px){
          width: 100%;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .btn-navbar {
      background: transparent !important;
      .fa {
        font-size: 30px;
        color: $color-l-blue;
        &.fa-close:before {
          content: "\f00d" !important;
        }
      }
      &:focus, &:active {
        outline: none !important;
        box-shadow: none;
      }
    }
  }
  a {
    &:hover {
      text-decoration: none;
    }
  }
  .slogan-top {
    font-family: $fontNL;
    font-size: 54px;
    line-height: 50px;
    display: inline-block;
    background: linear-gradient($color-l-blue, $color-d-blue);
    -webkit-background-clip: text;
    color: transparent;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 30px;
    }
    @media (max-width: 991px) and (min-width: 768px) {
      font-size: 23px;
    }
    @media (max-width: 767px) {
      padding: 5px 0 5px 0;
      font-size: 36px;
      line-height: 30px;
    }
  }
  &.fixed {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    background: #fff;
    box-shadow: 1px -2px 11px #3c3c3c;
    z-index: 99;
    margin: 0;
  }
  #grid-image {
    margin: 30px 0 150px 0;
    &:after {
      content: '';
      display: block;
      clear: both;
    }
    .hex {
      width: 196px;
      height: 112px;
      background-color: #fff;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      -webkit-background-size: auto;
      -moz-background-size: auto;
      -ms-background-size: auto;
      -o-background-size: auto;
      position: relative;
      float: left;
      margin: 34px 5px;
      text-align: center;
      z-index: 3;
      &.hex-white {
        background: #fff;
        @media (max-width: 991px) and (min-width: 768px) {
          display: none;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      &.hex-blue {
        background: $color-l-blue;
        @media (max-width: 991px) and (min-width: 768px) {
          display: none;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      &.logo {
        background: url($url +"logo.png") no-repeat center;
        & .corner-1, & .corner-2 {
          background: none;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }

      &.video {
        &:before {
          font: normal normal normal 22px/5 FontAwesome;
          color: #ffffff;
          content: "\f16a";
          text-shadow: 1px 1px 4px #3f3f3f;
          position: absolute;
          left: 0;
          width: 100%;
          -webkit-transition: .3s;
          -moz-transition: .3s;
          -ms-transition: .3s;
          -o-transition: .3s;
          transition: .3s;
          @media (max-width: 767px) {
            padding: 45px 0;
          }
        }
        &:hover:before {
          text-shadow: none;
        }
      }

      &:nth-child(1), &:nth-child(8) {
        margin-left: 108px;
        @media (max-width: 767px) {
          margin-left: 5px;
        }
      }
      &:nth-child(11) {
        margin-left: 210px;
        @media (max-width: 991px) and (min-width: 768px) {
          margin-left: 5px;
        }
      }

      &:nth-child(5), &:nth-child(8), &:nth-child(11), &:nth-child(14) {
        clear: left;
        @media (max-width: 991px) and (min-width: 768px) {
          clear: none;
        }
        @media (max-width: 767px) {
          clear: none;
          margin-left: 5px;
        }
      }
      &:nth-child(14) {
        margin-left: 520px;
        @media (max-width: 991px) and (min-width: 768px) {
          margin-left: 5px;
        }
      }
      &:nth-child(11), &:nth-child(14) {

        @media (max-width: 767px) {
          clear: none;
          margin-left: 5px;
        }
      }
      &:nth-child(10) {

        @media (max-width: 767px) {
          clear: none;
          margin-left: 5px;
        }
      }
      &:after {
        content: '';
        position: absolute;
        display: block;
        background: transparent;
        width: 100%;
        height: 100%;
        z-index: -3;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        -o-transition: .5s;
        transition: .5s;
      }
      .corner-1,
      .corner-2 {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: inherit;
        z-index: -2;
        overflow: hidden;
        -webkit-backface-visibility: hidden;
        -moz-backface-visibility: hidden;
        -ms-backface-visibility: hidden;
        -o-backface-visibility: hidden;
        backface-visibility: hidden;
        @media (max-width: 767px) {
          display: none;
        }
      }

      .corner-1 {
        z-index: -1;
        -webkit-transform: rotate(60deg);
        -moz-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
        -o-transform: rotate(60deg);
        transform: rotate(60deg);
        &:before {
          -webkit-transform: rotate(-60deg) translate(-113px, 0);
          -moz-transform: rotate(-60deg) translate(-113px, 0);
          -ms-transform: rotate(-60deg) translate(-113px, 0);
          -o-transform: rotate(-60deg) translate(-113px, 0);
          transform: rotate(-60deg) translate(-113px, 0);
          -webkit-transform-origin: 0 0;
          -moz-transform-origin: 0 0;
          -ms-transform-origin: 0 0;
          -o-transform-origin: 0 0;
          transform-origin: 0 0;
        }
      }

      .corner-2 {
        -webkit-transform: rotate(-60deg);
        -moz-transform: rotate(-60deg);
        -ms-transform: rotate(-60deg);
        -o-transform: rotate(-60deg);
        transform: rotate(-60deg);
        &:before {
          -webkit-transform: rotate(60deg) translate(-58px, -14px);
          -moz-transform: rotate(60deg) translate(-58px, -14px);
          -ms-transform: rotate(60deg) translate(-58px, -14px);
          -o-transform: rotate(60deg) translate(-58px, -14px);
          transform: rotate(60deg) translate(-58px, -14px);
          bottom: 0;
        }
      }

      .corner-1:before,
      .corner-2:before {
        width: 227px;
        height: 227px;
        content: '';
        position: absolute;
        background: inherit;
        top: 0;
        left: 0;
        z-index: 1;
        background-repeat: no-repeat;
      }
      .corner-1:after,
      .corner-2:after {
        content: '';
        position: absolute;
        display: block;
        background: transparent;
        width: 100%;
        height: 100%;
        z-index: 3;
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        -o-transition: .5s;
        transition: .5s;
      }
      &.hex-add {
        width: 195px;
        height: 112.58px;
        position: absolute;
        right: 22px;
        background-color: transparent;
        margin: 393px 0 0 0;
        border-left: solid 5px $color-l-blue;
        border-right: solid 5px $color-l-blue;
        font-size: 18px;
        text-transform: uppercase;
        -webkit-transition: .3s;
        -moz-transition: .3s;
        -ms-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        padding: 37px 10px;
        &:before, &:after {
          content: "";
          position: absolute;
          z-index: 1;
          width: 137.89px;
          height: 137.89px;
          -webkit-transform: scaleY(0.5774) rotate(-45deg);
          -ms-transform: scaleY(0.5774) rotate(-45deg);
          transform: scaleY(0.5774) rotate(-45deg);
          background-color: inherit;
          left: 23.5571px;
        }
        &:before {
          top: -68.9429px;
          border-top: solid 7.0711px $color-l-blue;
          border-right: solid 7.0711px $color-l-blue;
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:after {
          bottom: -68.9429px;
          border-bottom: solid 7.0711px $color-l-blue;
          border-left: solid 7.0711px $color-l-blue;
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:hover {
          text-decoration: none;
          border-color: #8bc7fb;
          &:before, &:after {
            border-color: #8bc7fb;
            background-color: transparent;
          }
        }
        @media (max-width: 991px) and (min-width: 768px) {
          right: 5px;
        }
        @media (max-width: 767px) {
          visibility: visible;
          animation-name: fadeIn;
          position: relative;
          margin: 5px;
          width: calc(50% - 10px);
          height: 200px;
          border: none;
          background: #67bffb;
          right: 0;
          color: #fff;
          padding: 75px 10px;

        }
        @media (max-width: 480px) {
          width: 100%;
        }
      }
      .inner-hex {
        opacity: 0;
        position: absolute;
        display: flex;
        width: 100%;
        left: 0;
        padding: 10px;
        height: 100%;
        top: 0;
        color: #fff;
        -webkit-transform: scale(0.9);
        -moz-transform: scale(0.9);
        -ms-transform: scale(0.9);
        -o-transform: scale(0.9);
        transform: scale(0.9);
        -webkit-transition: .5s;
        -moz-transition: .5s;
        -ms-transition: .5s;
        -o-transition: .5s;
        transition: .5s;
        span {
          margin: auto;
          font-size: 18px;
        }
        @media (max-width: 767px) {
          opacity: 1;
        }
      }
      &:hover {
        .inner-hex {
          opacity: 1;
          -webkit-transform: scale(1);
          -moz-transform: scale(1);
          -ms-transform: scale(1);
          -o-transform: scale(1);
          transform: scale(1);
        }
      }
      &.hex-3 {
        -webkit-animation-delay: 0.2s;
        -moz-animation-delay: 0.2s;
        -o-animation-delay: 0.2s;
        animation-delay: 0.2s;
      }
      &.hex-1 {
        -webkit-animation-delay: 0.4s;
        -moz-animation-delay: 0.4s;
        -o-animation-delay: 0.4s;
        animation-delay: 0.4s;
      }
      &.hex-6 {
        -webkit-animation-delay: 0.6s;
        -moz-animation-delay: 0.6s;
        -o-animation-delay: 0.6s;
        animation-delay: 0.6s;
      }
      &.hex-13 {
        -webkit-animation-delay: .8s;
        -moz-animation-delay: .8s;
        -o-animation-delay: .8s;
        animation-delay: .8s;
      }
      &.hex-4 {
        -webkit-animation-delay: 1s;
        -moz-animation-delay: 1s;
        -o-animation-delay: 1s;
        animation-delay: 1s;
      }
      &.hex-14 {
        -webkit-animation-delay: 1.2s;
        -moz-animation-delay: 1.2s;
        -o-animation-delay: 1.2s;
        animation-delay: 1.2s;
        @media (max-width: 991px) and (min-width: 768px) {
          left: 102px;
        }
      }
      &.hex-2 {
        -webkit-animation-delay: 1.4s;
        -moz-animation-delay: 1.4s;
        -o-animation-delay: 1.4s;
        animation-delay: 1.4s;
      }
      &.hex-5 {
        -webkit-animation-delay: 1.6s;
        -moz-animation-delay: 1.6s;
        -o-animation-delay: 1.6s;
        animation-delay: 1.6s;
      }
      &.hex-7 {
        -webkit-animation-delay: 1.8s;
        -moz-animation-delay: 1.8s;
        -o-animation-delay: 1.8s;
        animation-delay: 1.8s;
        @media (max-width: 991px) and (min-width: 768px) {
          left: 102px;
        }
      }
      &.hex-10 {
        -webkit-animation-delay: 2s;
        -moz-animation-delay: 2s;
        -o-animation-delay: 2s;
        animation-delay: 2s;
      }
      &.hex-8 {
        -webkit-animation-delay: 2.2s;
        -moz-animation-delay: 2.2s;
        -o-animation-delay: 2.2s;
        animation-delay: 2.2s;
      }
      &.hex-11 {
        -webkit-animation-delay: 2.4s;
        -moz-animation-delay: 2.4s;
        -o-animation-delay: 2.4s;
        animation-delay: 2.4s;
      }
      &.hex-9 {
        -webkit-animation-delay: 2.6s;
        -moz-animation-delay: 2.6s;
        -o-animation-delay: 2.6s;
        animation-delay: 2.6s;
      }
      &.hex-12 {
        -webkit-animation-delay: 2.8s;
        -moz-animation-delay: 2.8s;
        -o-animation-delay: 2.8s;
        animation-delay: 2.8s;
      }
      &.hex-15 {
        -webkit-animation-delay: 3s;
        -moz-animation-delay: 3s;
        -o-animation-delay: 3s;
        animation-delay: 3s;
      }
      @media (max-width: 767px) {
        width: calc(50% - 10px);
        background-size: cover;
        height: 200px;
        margin: 5px;
        &[class*="hex-"] {
          -webkit-animation-delay: .2s;
          -moz-animation-delay: .2s;
          -o-animation-delay: .2s;
          animation-delay: .2s;
        }
      }
      @media (max-width: 480px) {
        width: 100%;
        margin-left: auto !important;
        margin-right: auto;
      }
    }
    a.hex:hover {
      &:after, .corner-1:after,
      .corner-2:after {
        background: rgba(79, 151, 217, 0.8);
      }
      &.hover-white:hover{
        &:after, .corner-1:after,
        .corner-2:after {
          background: rgba(255, 255, 255, .24);
        }
      }
    }
    a.hex.logo:hover {
      &:after, .corner-1:after,
      .corner-2:after {
        background: transparent;
      }
    }
    @media (max-width: 767px) {
      margin: 5px auto 50px auto;
      width: 415px;
      max-width: 100%;
      a.hex {
        &:after, .corner-1:after,
        .corner-2:after {
          background: rgba(9, 16, 29, .6);
          animation-delay: 1s;
        }
      }
    }
    @media (max-width: 480px) {
      width: 198px;
    }

  }
  .heading-h3 {
    font-size: 26px;
    margin-bottom: 80px;
  }
  .section {
    text-align: center;
    .heading {
      text-transform: uppercase;
      text-align: center;
      margin: 15px 0 63px 0;
      &.heading-margin-sm {
        margin: 0 0 50px 0;
      }
    }
    .headind_sm {
      font-family: $fontNT;
      font-size: 20px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 50px;
      @media (max-width: 767px) {
        margin-bottom: 5px;
      }
    }

    &.last_developments {
      @media (max-width: 767px) {
        max-width: 350px;
        margin: 0 auto;
      }
      a {
        overflow: hidden;
        display: block;
        position: relative;
        &:hover {
          text-decoration: none;
        }
        &.inner-link {
          text-align: left;
          min-height: 60px;
        }
        .inner {
          font-family: $fontOS;
          font-size: 18px;
          font-weight: 600;
          color: $color-d-blue;
          display: block;
          -webkit-transition: .3s;
          -moz-transition: .3s;
          -ms-transition: .3s;
          -o-transition: .3s;
          transition: .3s;
          margin: auto;
          padding: 10px 0 0 0;
          max-height: 60px;
        }

        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          opacity: .7;
          -webkit-transition: .3s;
          -moz-transition: .3s;
          -ms-transition: .3s;
          -o-transition: .3s;
          transition: .3s;
        }

      }
      .last_dev {
        margin-bottom: 20px;
        .item {

        }
        &:hover {
          .inner {
            color: $color-l-blue;
          }
          img {
            opacity: .6;
            width: 100%;
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1);
          }
        }
      }

    }
    .owl-wrapper-outer {
      border: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
    }
    .owl-carousel {
      margin-bottom: 0;
    }
    .owl-controls {
      position: absolute;
      bottom: 0;
      width: 100%;
      left: 0;
      .owl-page {
        &.active {
          span {
            border: 2px solid #fff;
            background-color: transparent;
            box-sizing: content-box;
          }
        }
        span {
          background-color: rgba(225, 225, 225, 0.6);
          box-shadow: none;
          border: 7px solid transparent;
          &:hover {
            border: 2px solid #fff;
            background-color: transparent;
            box-sizing: content-box;
          }
        }
      }
      .owl-pagination {
        top: auto;
        bottom: 5px;
      }
    }
  }
  .about_claster {
    margin-bottom: 112px;
    font-size: 10px;
    @media (min-width: 992px) and (max-width: 1199px) {
      font-size: 7px;
    }
    @media (max-width: 991px) and (min-width: 768px) {
      font-size: 7px;
    }
    .about-col {
      font-family: $fontOS;
      padding: 0 45px;
      font-size: 1.8em;
      text-align: left;
      .headind_sm {
        font-family: $fontNL;
        font-size: 2.1em;
        line-height: 35px;
        letter-spacing: -1px;
        margin-bottom: 22px;
      }
      @media (max-width: 991px) and (min-width: 768px) {
        padding: 0 15px;
      }
      @media (max-width: 767px) {
        padding: 30px 15px;
      }
      &:nth-child(3n+3):before, &:nth-child(3n+3):after {
        content: '';
        width: 1px;
        height: 240px;
        background: #c2cad0;
        position: absolute;
        display: block;
        bottom: 0;
        opacity: .25;
        @media (max-width: 991px) and (min-width: 768px) {
          display: none;
        }
        @media (max-width: 767px) {
          display: none;
        }
      }
      &:nth-child(3n+3):before {
        left: 0;
      }
      &:nth-child(3n+3):after {
        right: 0;
      }
    }
    img {
      margin: 0 auto 20px auto;
      text-align: center;
      display: block;
    }
    @media (max-width: 767px) {
      margin-bottom: 30px;
    }
  }
  .plan_claster {
    @extend .about_claster;
    margin-bottom: 90px;
    .img {
      height: 105px;
      img {
        max-height: 100%;
        width: auto;
      }
    }
    .headind_sm {
      font-family: $fontNSB;
      font-size: 22px;
      text-transform: none;
      margin-bottom: 25px;
    }
    .about-col {
      padding: 0 20px;
      &:nth-child(3n+3):before, &:nth-child(3n+3):after {
        display: none;
      }
      @media (max-width: 767px) {
        padding: 30px 15px;
      }
    }
  }
  #content .btn.btn-border, .btn.btn-border {
    background: #fff;
    border: 1px solid $color-l-blue;
    color: $color-d-blue;
    font-size: 14px;
    font-family: $fontOS;
    font-weight: 600;
    text-transform: none;
    min-width: 180px;
    padding: 10px 35px;
    border-radius: 0;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    &:hover {
      background: $color-l-blue;
      color: #fff;
    }
  }
  .buttons-wrap{
    margin-top: 20px;
  }
  .btn-blue {
    background: $color-l-blue;
    color: #fff;
    font-size: 16px;
    font-family: $fontNT;
    text-transform: uppercase;
    text-align: center;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
    padding: 10px;
    height: auto;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    margin-right: 0;
    margin-left: 0;
    margin: 20px 0 115px;
    &:hover {
      background: $color-d-blue;
      color: #fff;
    }
    &.btn-b {
      min-width: 305px;
      margin: 0;
      font-size: 18px;
      font-family: $fontOS !important;
      font-weight: 600;
      padding: 18px 20px !important;
    }
    &.btn-s {
      min-width: 165px;
      line-height: 1.42857143;
      margin: 0;
    }
  }
  .margin-b-150 {
    margin-bottom: 50px;
    @media (max-width: 767px) {
      margin-bottom: 50px;
    }
  }
  .margin-b-90 {
    margin-bottom: 90px;
  }

  .top-line {
    &:before {
      content: '';
      width: 555px;
      max-width: 100%;
      height: 1px;
      background: #c2cad0;
      display: block;
      margin: 113px auto;
      @media (max-width: 767px) {
        margin: 48px auto;
        width: 160px;
      }
    }
  }
  .bottom-line {
    &:after {
      content: '';
      width: 555px;
      max-width: 100%;
      height: 1px;
      background: #c2cad0;
      display: block;
      margin: 90px auto 90px auto;
      @media (max-width: 767px) {
        margin: 48px auto;
        width: 160px;
      }
    }
  }
  .margin-b {
    margin-bottom: 125px;
  }
  .logo-home {
    ul {
      li {
        text-align: center;

        img {
          padding: 0 5px;
          @media (max-width: 767px) {
            max-width: 100%;
          }
        }
      }
    }
    &:before {
      content: '';
      width: 555px;
      max-width: 100%;
      height: 1px;
      background: #c2cad0;
      display: block;
      margin: 113px auto;
      @media (max-width: 767px) {
        margin: 48px auto;
        width: 160px;
      }
    }
    &:after {
      content: '';
      display: none;
      width: 555px;
      max-width: 100%;
      height: 1px;
      background: #c2cad0;
      margin: 90px auto 90px auto;
      @media (max-width: 767px) {
        margin: 48px auto;
        width: 160px;
      }
    }
    .owl-wrapper-outer {
      border: none;
      box-shadow: none;
    }
    .owl-controls {
      .owl-page {
        &.active {
          span {
            background-color: $color-l-blue;
          }
        }
        span {
          background-color: #c2cad0;
          box-shadow: none;
          &:hover {
            background-color: $color-l-blue;
          }
        }
      }
    }
  }
  .newsletter {
    /*&:before{
            content: '';
            width: 360px;
            height: 1px;
            background: #c2cad0;
            display: block;
            margin: 42px auto 150px auto;
            @media (max-width: 767px){
                width: 160px;
                margin: 48px auto;
            }
        }*/
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
  .newsletter-bg {
    background: #f6f8f8;
    color: $color-black;
    font-family: $fontOS;
    font-weight: 200;
    font-size: 25px;
    padding: 35px 0;
    margin-top: 90px;
    .msg-warning {
      font-size: 16px;
      padding-top: 15px;
    }
    input {
      border: 1px solid #c5cdd3;
      border-radius: 0;
      width: 360px;
      height: 40px;
      margin-right: 20px;
      @media (max-width: 767px) {
        width: 200px;
      }
    }
  }
  input {
    min-width: 350px;
    height: 35px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    margin: 0 8px;
    color: #c2cad0;
    padding: 2px 10px;
    text-align: center;
    &::-webkit-input-placeholder {
      text-align: center;
      font-family: $fontOS;
      font-weight: 200;
      font-size: 16px;
      color: #c2cad0;
    }
    &:-moz-placeholder {
      text-align: center;
      font-family: $fontOS;
      font-weight: 200;
      font-size: 16px;
      color: #c2cad0;
    }
    &:-ms-input-placeholder {
      text-align: center;
      font-family: $fontOS;
      font-weight: 200;
      font-size: 16px;
      color: #c2cad0;
    }
    &::-moz-placeholder {
      text-align: center;
      font-family: $fontOS;
      font-weight: 200;
      font-size: 16px;
      color: #c2cad0;
    }
    @media (max-width: 767px) {
      min-width: 200px;
    }
  }
  p {
    margin-bottom: 20px;
  }
  .btn-news {
    background-color: $color-d-blue;
    min-height: 35px;
    color: #fff;
    font-family: $fontNSB;
    font-size: 18px;
    text-transform: uppercase;
    border-radius: 0;
    &:hover {
      background-color: $color-l-blue;
      color: #fff;
    }
    &:focus, &:active {
      outline: none;
      background-color: $color-l-blue;
      color: #fff;
      box-shadow: none;
    }
  }
  footer {
    padding-top: 30px;
    background: #5eafe9;
    .navbar-nav {
      float: none;
      margin-bottom: 35px;
      & > li {
        float: none;
        display: inline-block;
        a {
          font-family: $fontNSB;
          color: #fff;
          text-transform: uppercase;
          font-size: 14px;
          padding-left: 12px;
          padding-right: 12px;
          &:hover {
            color: #fff;
            background: transparent;
            text-decoration: underline;
          }
        }
      }
    }
    .soc-hex {
      display: inline-block;
      position: relative;
      margin: 0 8px;
      width: 35px;
      height: 20.21px;
      background-color: #fff;
      transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
      -webkit-transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0;
        border-left: 17.5px solid transparent;
        border-right: 17.5px solid transparent;
        left: 0;
        right: 0;
      }

      &:before {
        bottom: 100%;
        border-bottom: 10.10px solid #fff;
      }

      &:after {
        top: 100%;
        width: 0;
        border-top: 10.10px solid #fff;
      }
      .fa {
        color: $color-l-blue;
        font-size: 21px;
        line-height: 22px;
      }
      &:hover {
        transform: scale3d(1.2, 1.2, 1);
        -webkit-transform: scale3d(1.2, 1.2, 1);
        transition: all 0.35s cubic-bezier(0.000, 1.270, 0.460, 1.650);
        -webkit-transition: all 0.35s cubic-bezier(0.000, 1.270, 0.460, 1.650);
      }
    }
    .phone, .mail {
      color: #1f7fc8;
      @media (max-width: 767px) {
        margin: 20px 0;
      }

    }
    .copyright {
      font-size: 13px;
      margin-top: 15px;
      color: #fff;
    }
    div {
      @media (max-width: 767px) {
        text-align: center !important;
      }
    }
    .contact-info {
      color: #fff;
      font-size: 14px;
      margin-top: 30px;
      font-family: $fontOS;
      p {
        margin-bottom: 0;
      }
    }
    #scroller {
      position: fixed;
      z-index: 999;
      bottom: 10px;
      right: 50px;
      display: none;
      i {
        font-size: 60px;
        color: #ddd;
        -webkit-transition: .5s;
        -moz-transition: 0.5s;
        -ms-transition: 0.5s;
        -o-transition: 0.5s;
        transition: 0.5s;
        -webkit-transform-origin: 50% 50%;
        -moz-transform-origin: 50% 50%;
        -ms-transform-origin: 50% 50%;
        -o-transform-origin: 50% 50%;
        transform-origin: 50% 50%;
        &:hover {
          cursor: pointer;
          color: #b9b9b9;
          font-size: 65px;
        }
      }
    }
  }
  // project page
  .pre_text {
    margin-bottom: 65px;
    p {
      columns: 2;
      -moz-columns: 2;
      -webkit-columns: 2;
      text-align: left;
      -webkit-column-gap: 135px;
      -moz-column-gap: 135px;
      column-gap: 135px;
      @media (max-width: 767px) {
        columns: 1;
        -moz-columns: 1;
        -webkit-columns: 1;
        text-align: center;
      }
    }
    &.col-1 {
      p {
        columns: 1;
        text-align: center;
      }
    }
  }
  .project {
    & > div {
      margin: 30px 0;
      &:nth-child(3n+4) {
        clear: left;
        @media (max-width: 991px) and (min-width: 768px) {
          clear: none;
        }

      }
      @media (max-width: 991px) and (min-width: 768px) {
        &:nth-child(2n+3) {
          clear: left;
        }
      }
    }
    a {
      &:hover {
        text-decoration: none;
        .img {
          img {
            width: 100%;
            height: 200px;
            object-fit: cover;
            opacity: .6;
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -ms-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1);
          }
        }
        .inner {
          color: $color-d-blue;
        }
        .date {
          background: rgba(255, 255, 255, .86);
          color: #7b7b7b;
        }
      }
      .img {
        -webkit-transition: .3s;
        -moz-transition: .3s;
        -ms-transition: .3s;
        -o-transition: .3s;
        transition: .3s;
        overflow: hidden;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        img {
          width: 100%;
          height: 200px;
          object-fit: cover;
          opacity: .7;
          -webkit-transition: .3s;
          -moz-transition: .3s;
          -ms-transition: .3s;
          -o-transition: .3s;
          transition: .3s;
        }
        @media (max-width: 767px) {
          max-width: 300px;
          margin: 0 auto;
        }
      }
      .inner {
        font-family: $fontOS;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
        color: #1887de;
        padding-top: 10px;
        display: inline-block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .date {
    position: absolute;
    right: 15px;
    top: 15px;
    background: rgba(24, 135, 222, .86);
    min-width: 165px;
    padding: 5px 10px 8px 10px;
    color: #fff;
    opacity: 1;
    z-index: 99;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -ms-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
    .day {
      font-size: 43px;
      float: left;
      line-height: 34px;
      padding-right: 5px;
    }
    .date-more {
      float: left;
      .month {
        font-size: 18px;
        line-height: 21px;
      }
      .year {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
  .all-news {
    font-size: 18px;
    color: $color-d-blue;
    margin-top: 50px;
    display: inline-block;
    &:after {
      content: '\f107';
      display: inline-block;
      font-family: FontAwesome;
      padding-left: 5px;
      font-size: 16px;
    }
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .description {
    p {
      margin-bottom: 30px;
      font-size: 18px;
    }
    .pre-img {
      min-width: 100%;
      height: 290px;
      object-fit: cover;
      margin-bottom: 20px;
      margin-top: 20px;
    }
  }
  .date-page {
    color: #7b7b7b;
  }
  #share42 {
    a {
      &:hover {
        opacity: .7;
      }
    }
  }
  .contact-form {
    background: #fff;
    color: #7b7b7b;
    font-size: 16px;
    @media (max-width: 767px) {
      width: 100%;
      position: relative;
      top: 0;
    }
    .mail {
      color: $color-d-blue;
    }
    .info{
      font-family: $fontOS;
      font-size: 18px;
      font-weight: 300;
      color: $color-black;
      .contact-title {
        font-weight: bold;
        margin-bottom: 5px;
        margin-top: 20px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
  .form-contact {
    border-radius: 0;
    background: #fff;
    border: 1px solid #c5cdd3;
    text-align: left;
    &::-webkit-input-placeholder {
      font-family: "NerisLight", sans-serif;
      font-size: 16px;
      color: #c2cad0;
    }
    &:-moz-placeholder {
      font-family: "NerisLight", sans-serif;
      font-size: 16px;
      color: #c2cad0;
    }
    &:-ms-input-placeholder {
      font-family: "NerisLight", sans-serif;
      font-size: 16px;
      color: #c2cad0;
    }
    &::-moz-placeholder {
      font-family: "NerisLight", sans-serif;
      font-size: 16px;
      color: #c2cad0;
    }
  }
  .mail-form {
    .tab-form {
      input, textarea {
        min-height: 50px;
      }
      textarea {
        min-height: 110px;
      }
      .form-group{
        margin-bottom: 20px;
      }
    }
  }
  .mail-form {
    .contact-title, .contact-title:first-child {
      margin-top: 50px;
      font-family: $fontOS;
      font-size: 18px;
      color: $color-black;
      font-weight: bold;
      margin-bottom: 5px;
    }
    .form-group{
      margin-bottom: 10px;
    }
    input, textarea {
      max-width: 100%;
      min-width: 200px;
      margin: 0;
      line-height: 15px;
      min-height: 40px;
      box-shadow: none;
      font-family: $fontOS;
      font-weight: 200;
      font-size: 18px;
      &:focus{
        box-shadow: none;
        border-color: #777;
      }
      &::-webkit-input-placeholder {
        font-family: $fontOS;
        font-weight: 200;
        font-size: 18px;
        text-align: left;
        line-height: 26px;
      }
      &:-moz-placeholder {
        font-family: $fontOS;
        font-weight: 200;
        font-size: 18px;
        text-align: left;
        line-height: 26px;
      }
      &:-ms-input-placeholder {
        font-family: $fontOS;
        font-weight: 200;
        font-size: 18px;
        text-align: left;
        line-height: 26px;
      }
      &::-moz-placeholder {
        font-family: $fontOS;
        font-weight: 200;
        font-size: 18px;
        text-align: left;
        line-height: 26px;
      }
    }
    textarea {
      resize: none;
      min-height: 80px;
      line-height: 26px;
    }
    .fileform{
      background: #f9fafb;
      margin-bottom: 20px;
      .fileformlabel{
        display: inline-block;
      }
    }
    .upload-file {
      font-family: $fontOS;
      font-size: 14px;
      font-weight: 400;
      color: #60B2EA;
      margin: 10px 15px;
      cursor: pointer;
    }
    .btn-form{
      width: 360px;
      min-width: 100%;
      margin-top: 20px;
      font-weight: 400;
    }
  }
  .contact-user {
    padding: 0;
    margin-bottom: 85px;
    .img-hex {
      width: 195px;
      height: 226px;
      overflow: hidden;
      position: relative;
      margin: 0 auto 25px auto;
      &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: url($url + "mask-hexagon.png");
      }
    }
    .name {
      display: block;
      font-family: $fontNSB;
      font-size: 18px;
      text-transform: uppercase;
    }
    .post {
      font-size: 16px;
      display: block;
      margin: 3px 0 2px 0;
    }
    .email {
      font-size: 16px;
      color: $color-d-blue;
      @media (max-width: 767px) {
        font-size: 15px;
      }
    }
    &.contact-user-sm {
      text-align: left;
      padding-left: 30px;
      padding-top: 30px;
      .img-hex {
        width: 100px;
        height: 115px;
        margin: 0 0 25px 0;
        &:before {
          background-size: contain;
        }
      }

    }
  }
  .map-grey {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    @media (max-width: 767px) {
      margin: 0 auto;
      width: 85%;
      height: 350px;
      display: block;
    }
  }
  .map-api {
    height: 520px;
    @media (min-width: 768px) and (max-width: 991px){
      margin-top: 20px;
    }
    @media (max-width: 767px) {
      margin: 20px 30px;
      height: 300px;
    }
  }
  .map {
    position: relative;
    margin-bottom: 85px;
  }
  .page-404 {
    .heading {
      color: #5eafe9;
      font-size: 170px;
      line-height: 170px;
    }
    .heading-2 {
      display: block;
      font-size: 50px;
      line-height: 74px;
      margin: 50px 0;
    }
    @media (max-width: 767px) {
      text-align: center;
      .heading {
        font-size: 130px;
        line-height: 130px;
      }
      .heading-2 {
        font-size: 24px;
        line-height: 24px;
        margin: 40px 0;
      }
    }
  }
  .item_text {
    text-align: left;
    h5 {
      font-family: $fontNSB;
      font-size: 18px;
      &:before {
        content: '\f0da';
        display: inline-block;
        font-family: FontAwesome;
        padding-right: 7px;
        font-size: 16px;
        color: $color-l-blue;
      }
    }
  }
  .text-grey {
    font-size: 14px;
    color: #7b7b7b;
  }
  .logo-it {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      display: block;
    }
    li {
      flex: 0 0 25%;
      padding-top: 60px;
      img {
        margin: 0 auto;
      }
    }
  }
  .add-it {
    margin-top: 35px;
    .heading-h3 {
      margin-bottom: 50px;
    }
    .text-grey {
      margin: 50px 0 80px 0;
    }
    &.add-it-left {
      .btn-blue {
        margin-top: 20px;
      }
    }
  }
  .tab-form {
    .has-error {
      border: 1px solid #a94442;
    }
    .capcha-container {
      display: inline-block;
      border-radius: 4px;
      width: 304px;
      .capcha-text {
        padding-left: 10px;
        text-align: left;
      }
    }
    .fileform {
      text-align: left;
      background-color: #F5F5F5;
      margin-top: 10px;
    }
    .btn-form {
      background: #67bffb;
      color: #fff;
      font-size: 16px;
      font-family: "NerisThin", sans-serif;
      text-align: center;
      border-radius: 0;
      padding: 10px 100px;
      height: auto;
      text-transform: uppercase;
      margin: 15px 0;
    }

    .mail-form {
      margin-top: 60px;
      .buttons {
        & > div {
          @media (max-width: 520px) {
            float: none !important;
            margin-bottom: 10px;
          }
        }
      }
      & + div {
        .text-grey {
          margin: 50px 0 47px 0;
          @media (min-width: 992px) and (max-width: 1199px) {
            margin: 30px 0 41px 0;
          }
          @media (max-width: 991px) and (min-width: 768px) {
            margin: 5px 0 5px 0;
            line-height: 16px;
          }
        }
      }
      .fileformlabel {
        position: absolute;
        text-align: left;
        display: inline-block;
        margin: 10px;
        @media (max-width: 520px) {
          position: relative;
          text-align: center;
        }
      }
    }
  }

  // svg 404
  svg {
    -webkit-transform: scale(.95);
    -moz-transform: scale(.95);
    -ms-transform: scale(.95);
    -o-transform: scale(.95);
    transform: scale(.95);
  }
  #Polygon-1, #Polygon-2, #Polygon-3, #Polygon-4, #Polygon-4, #Polygon-5 {
    animation: float 1s infinite ease-in-out alternate;
  }
  #circle1 {
    transform: translateX(350px) translateY(10px);
  }
  #circle2 {
    transform: translateX(625px) translateY(170px);
  }
  #circle3 {
    transform: translateX(495px) translateY(558px);
  }
  #circle4 {
    transform: translateX(182px) translateY(864px);
  }
  #circle5 {
    transform: translateX(79px) translateY(533px);
  }
  #circle6 {
    transform: translateX(146px) translateY(610px);
  }
  #circle7 {
    transform: translateX(746px) translateY(410px);
    animation: circle 1s infinite ease-in-out alternate;
  }
  #circle8 {
    transform: translateX(778px) translateY(444px);
    animation: circle1 1s infinite ease-in-out alternate;
  }
  #Polygon-2 {
    animation-delay: .2s;
  }
  #Polygon-3 {
    animation-delay: .4s;
  }
  #Polygon-4 {
    animation-delay: .6s;
  }
  #Polygon-5 {
    animation-delay: .8s;
  }

  @keyframes float {
    100% {
      transform: translateY(20px);
    }
  }
  @keyframes circle {
    100% {
      transform: translateX(746px) translateY(430px);
    }
  }
  @keyframes circle1 {
    100% {
      transform: translateX(778px) translateY(484px);
    }
  }
  @media (max-width: 991px) {
    svg {
      -webkit-transform: scale(.8);
      -moz-transform: scale(.8);
      -ms-transform: scale(.8);
      -o-transform: scale(.8);
      transform: scale(.8);
    }
  }
}

.ie8 {
  body {
    .container {
      width: 1170px;
    }
    .nav > li {
      float: left;
      & > a {
        font-size: 14px;
        color: #09101d;
        text-transform: uppercase;
      }
    }
    .submenu, .hex-white, .hex-blue, .hex.logo {
      display: none;
    }
    .hex, .about-col, .animated {
      visibility: visible !important;
    }
    .hex-add.hex:before, .hex-add.hex:after {
      border: none !important;
    }
    .hex-add.hex {
      border: 4px solid $color-l-blue;
      position: relative !important;
      top: 0;
      margin: 34px 5px !important;
      right: 0 !important;
    }

    #grid-image {
      margin-bottom: 50px;
      .hex, #grid-image .hex-add.hex {
        width: 218px;
        height: 150px;
      }
      .hex {
        .inner-hex {
          background: rgba(9, 16, 29, 0.63);
          filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#90333333, endColorstr=#90333333);
        }
        &.video:before {
          text-align: center;
          color: #848484;
        }
      }
    }
    .last_developments.section A .inner {
      color: #333;
      padding-bottom: 10px;
    }
    .logo-home ul {
      text-align: center;
      li {
        display: inline-block;
        padding: 0 20px;
      }
    }

    .footer .navbar-nav {
      float: none;
      margin-bottom: 65px;
      & > li {
        float: none;
        display: inline-block;
        a {
          font-family: $fontNSB;
          color: #c2cad0;
          &:hover {
            color: $color-l-blue;
            background: transparent;
          }
        }
      }
    }
    .footer .soc-hex {
      display: inline-block;
      position: relative;
      margin: 0 8px;
      width: 35px;
      height: 20.21px;
      background-color: $color-l-blue;
      transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
      -webkit-transition: all 0.2s cubic-bezier(0.215, 0.610, 0.355, 1.000);
      &:before,
      &:after {
        content: "";
        position: absolute;
        width: 0;
        border-left: 17.5px solid transparent;
        border-right: 17.5px solid transparent;
        left: 0;
        right: 0;
      }

      &:before {
        bottom: 100%;
        border-bottom: 10.10px solid $color-l-blue;
      }

      &:after {
        top: 100%;
        width: 0;
        border-top: 10.10px solid $color-l-blue;
      }
      .fa {
        color: #fff;
        font-size: 21px;
        line-height: 22px;
      }
      &:hover {
        transform: scale3d(1.2, 1.2, 1);
        -webkit-transform: scale3d(1.2, 1.2, 1);
        transition: all 0.35s cubic-bezier(0.000, 1.270, 0.460, 1.650);
        -webkit-transition: all 0.35s cubic-bezier(0.000, 1.270, 0.460, 1.650);
      }
    }
    .footer .phone, .footer .mail {
      color: #1f7fc8;
    }
    .footer .copyright {
      font-size: 13px;
      margin-top: 60px;
    }
    .col-md-5 {
      width: 41.66666667%;
      float: left;
    }
    .col-md-2 {
      width: 16.66666667%;
      float: left;
    }
    .col-sm-4 {
      width: 33.33333333%;
    }
  }

}

//ABOUT US
#about_us {
  p {
    font-size: 18px;
  }

  .activity {
    text-align: center;
    p {
      font-family: $fontOS;
      font-style: italic;
      font-weight: 300;

    }
    &:before {
      content: '';
      width: 555px;
      max-width: 100%;
      height: 1px;
      background: #c2cad0;
      display: block;
      margin: 38px auto;
    }
    &:after {
      content: '';
      width: 555px;
      max-width: 100%;
      height: 1px;
      background: #c2cad0;
      display: block;
      margin: 38px auto 0 auto;
    }
  }

  .advantages {
    h2 {
      font-size: 3.8rem;
      text-transform: uppercase;
      font-family: $fontNL;
      background-image: url(../image/arrow.png);
      background-repeat: no-repeat;
      background-position-y: 10px;
      margin-left: -25px;
      padding-left: 25px;
      text-align: left;
      margin-top: 65px;
      margin-bottom: 25px;
    }
    strong {
      font-size: 18px;
    }
  }
  input {
    margin: 25px 0 120px;
    height: 60px;
    font-size: 18px;
  }
}

.interest {
  p {
    font-family: $fontOS;
    margin-bottom: 35px;
    font-weight: 300;
    font-size: 18px;
  }
  &:before {
    content: '';
    width: 555px;
    max-width: 100%;
    height: 1px;
    background: #c2cad0;
    display: block;
    margin: 120px auto;
  }
  .btn {
    background: #67bffb;
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    font-family: $fontOS;
    text-align: center;
    border-radius: 0;
    padding: 10px 35px;
    height: auto;
  }
}

// APPLICATION
#application, .application {
  text-align: left;
  .advantages {
    margin-top: 80px;
    h2 {
      font-size: 3.8rem;
      margin-bottom: 30px;
      text-transform: uppercase;
      font-family: $fontNL;
      background-image: url(../image/arrow.png);
      background-repeat: no-repeat;
      background-position-y: 10px;
      margin-left: -25px;
      padding-left: 25px;
      text-align: left;
    }
    .steps {
      padding: 0;
      p {
        font-size: 18px;
        font-family: $fontOS;
      }
    }
    .step1 {
      padding: 0;
      p {
        background-image: url(../image/1.png);
        background-repeat: no-repeat;
        background-position-y: 10px;
        padding-top: 140px;
      }
      @media (max-width: 767px) {
        p {
          background-position-x: 50%;
          text-align: center;
        }
      }
    }
    .step1:after {
      content: "";
      display: inline-block;
      width: 70px;
      height: 1px;
      background-color: #f0f2f4;
      position: relative;
      top: -160px;
      left: 175px;
      @media (max-width: 1200px) {
        display: none;
      }
    }
    .step2 {
      padding: 0;
      p {
        background-image: url(../image/2.png);
        background-repeat: no-repeat;
        background-position-y: 10px;
        padding-top: 140px;
      }
      @media (max-width: 767px) {
        p {
          background-position-x: 50%;
          text-align: center;
        }
      }
    }
    .step2:after {
      content: "";
      display: inline-block;
      width: 70px;
      height: 1px;
      background-color: #f0f2f4;
      position: relative;
      top: -207px;
      left: 175px;
      @media (max-width: 1200px) {
        display: none;
      }
    }
    .step3 {
      padding: 0;
      p {
        background-image: url(../image/3.png);
        background-repeat: no-repeat;
        background-position-y: 10px;
        padding-top: 140px;
      }
      @media (max-width: 767px) {
        p {
          background-position-x: 50%;
          text-align: center;
        }
      }
    }

    span {
      font-family: $fontOS;
      padding: 0;
      display: inline-block;
      font-size: 14px;
      color: #7b7b7b;
    }

  }
  .interest {
    &:before {
      content: '';
      width: 360px;
      height: 1px;
      background: #c2cad0;
      display: block;
      margin: 120px auto;
    }

  }

}

// partaking
#partaking, #partnership {
  .advantages {
    h2 {
      font-size: 3.8rem;
      margin: 0 0 20px;
      text-transform: uppercase;
      font-family: $fontNL;
      background-image: url(../image/arrow.png);
      background-repeat: no-repeat;
      background-position-y: 10px;
      margin-left: -25px;
      padding-left: 25px;
      text-align: left;
    }
    strong {
      font-size: 18px;
    }
  }
}

//TEAM
#team {
  p {
    font-size: 18px;
  }
}

#meeting {
  h2 {
    font-family: $fontNL;
    text-transform: uppercase;
    text-align: center;
    margin: 25px 0 70px;
    font-size: 5.6rem;
  }
  .title > p {
    font-family: $fontNL;
    font-style: italic;
    text-align: center;
    margin: 25px 0 50px;
    font-size: 20px;
  }
  .advantages {
    h3 {
      font-size: 3.8rem;
      margin: 50px 0 20px;
      text-transform: uppercase;
      font-family: $fontNL;
      background-image: url(../image/arrow.png);
      background-repeat: no-repeat;
      background-position-y: 10px;
      margin-left: -25px;
      padding-left: 25px;
      @media (max-width: 767px) {
        background-image: none;
      }
    }
  }
  a {
    text-decoration: underline;
    font-size: 18px;
    font-family: $fontOS;
    font-weight: 600;
  }
}

// COUNSEL
#counsel {
  .title > p {
    font-family: $fontNL;
    font-style: italic;
    text-align: center;
    margin: 25px 0 50px;
    font-size: 20px;
  }
  .advantages {
    h3 {
      font-size: 3.8rem;
      margin: 50px 0 20px;
      text-transform: uppercase;
      font-family: $fontNL;
      background-image: url(../image/arrow.png);
      background-repeat: no-repeat;
      background-position-y: 10px;
      margin-left: -25px;
      padding-left: 25px;
      @media (max-width: 767px) {
        background-image: none;
      }
    }
  }

}

// TARGET
#target {
  h2 {
    font-family: $fontNL;
    text-transform: uppercase;
    text-align: center;
    margin: 25px 0 70px;
    font-size: 5.6rem;
  }
  p {
    font-size: 18px;
  }
  .activity {
    text-align: center;
    p {
      font-family: $fontNL;
      font-style: italic;
    }
    &:before {
      content: '';
      width: 360px;
      height: 1px;
      background: #c2cad0;
      display: block;
      margin: 38px auto;
    }
    &:after {
      content: '';
      width: 360px;
      height: 1px;
      background: #c2cad0;
      display: block;
      margin: 38px auto 50px auto;
    }
  }
  .plan_claster {
    p {
      font-size: 14px;
      text-align: center;
    }
    h2 {
      font-size: 3.8rem;
    }
    .headind_sm {
      font-family: $fontOS;
      font-size: 18px;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }
}

// PROJECT

#project {
  p {
    font-size: 18px;
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    li {
      display: inline-block;
      background-color: #f0f2f4;

      a {
        color: black;
      }

    }
  }
  .project span {
    color: #1887de;
  }
  img {
    opacity: 1;
  }

  input {
    color: #1887de;
    margin: 25px 0 120px;
    height: 40px;
    font-size: 18px;
    min-width: 200px;
    background-color: #fff;
    border: 1px solid #60b2ea;
    border-radius: 0;
  }
}

// PROJECT-ITEM
.social {
  text-align: right;
  i {
    padding: 0 14px;
    color: #c5cdd3;
    &:hover {
      //background-image: url(../image/border.png);
      background-position: center;
      background-repeat: no-repeat;
      opacity: .7;
    }
  }
  i {
    font-size: 24px;
    line-height: 44px;
  }
}
#project-item {
  ul {
    font-size: 18px;
  }
  p {
    font-size: 18px;
  }

  h4 {
    margin: 60px 0 25px;
    font-size: 25px;
    font-family: "NerisLight", sans-serif;
    text-transform: uppercase;
  }
  .btn {
    background: #67bffb;
    color: #fff;
    font-size: 16px;
    font-family: "NerisThin", sans-serif;
    text-align: center;
    border-radius: 0;
    padding: 10px 35px;
    height: auto;
    &:hover {
      background: #1887de;
      color: #fff;
    }
  }
  .tags_social {
    margin: 30px 0;
  }
  .mainHeader {
    h2 {
      margin-top: 0;
    }
    .tags_social {
      margin: 30px 0;
    }
    .social {
      text-align: right;
      i {
        padding: 0 14px;
        color: #c5cdd3;
        &:hover {
          //background-image: url(../image/border.png);
          background-position: center;
          background-repeat: no-repeat;
          opacity: .7;
        }
      }
      i {
        font-size: 24px;
        line-height: 44px;
      }
    }
    .tags {

      a {
        font-size: 12px;
      }

    }
    .text-description {
      margin: 30px 0;
    }

  }
  @media (max-width: 992px) {
    .mainHeader {
      .social {
        text-align: center;
        margin-top: 20px;
      }
    }

  }

  .projectTasks {
    ul {
      list-style: none;
      margin-left: 20px;
      li {
        display: inline-block;
        background-color: transparent;
        padding: 5px 10px;
        margin: 10px 10px 0 0;

        a {
          color: black;
        }

      }

    }
  }
  //SLIDER-CAROUSEL

  #project-carousel {
    margin: 75px 0;
    text-align: center;
    .owl-wrapper-outer {
      border: none;
      box-shadow: none;
    }

  }

  .owl-buttons div {
    opacity: 1;
    text-shadow: none;
    width: 40px;
    height: 55px;

  }
  .owl-buttons .owl-prev {
    div {

      background-image: url(../image/arrowleftH.png);
      background-position: center;
      background-repeat: no-repeat;
      &:hover {
        background-image: url(../image/arrowleft.png);
      }
    }
    left: -45px;
    top: 35%;

  }

  .owl-buttons .owl-next {
    div {
      background-image: url(../image/arrowrightH.png);
      background-position: center;
      background-repeat: no-repeat;
      &:hover {
        background-image: url(../image/arrowright.png);
      }
    }
    right: -45px;
    top: 35%;
  }

  @media (max-width: 767px) {
    .owl-buttons div {
      width: 35px;
      height: 55px;

    }
    .owl-buttons .owl-prev {
      div {

        background-image: url(../image/leftwhite.png);

        &:hover {
          background-image: url(../image/leftwhite.png);
        }
      }
      left: 0;
      background-color: #c5cdd3;
      &:hover {
        background-color: #5eafe9;
      }
    }
    .owl-buttons .owl-next {

      div {

        background-image: url(../image/rightwhite.png);

        &:hover {
          background-image: url(../image/rightwhite.png);
        }
      }

      right: 0;
      background-color: #c5cdd3;
      &:hover {
        background-color: #5eafe9;
      }

    }
  }
  .directions {

    ol {
      font-size: 18px;
      li {
        margin: 30px 0;
      }
    }

  }
  .otherNews {
    h2 {
      text-align: left;
    }
    p {
      a {
        font-family: "NerisSemiBold", sans-serif;
        color: #1887de;
        padding-top: 10px;
        display: inline-block;
      }

    }

    p {
      margin: 8px 0;
    }

  }

  .OtherProjects {
    h2 {
      text-align: center;
      text-transform: uppercase;
    }
    &:before {
      content: '';
      width: 100%;
      height: 1px;
      background: #c2cad0;
      display: block;
      margin: 100px auto 100px;
    }
  }
  .project {

    span {
      color: #1887de;
    }
  }

  img {
    opacity: 1;
  }

  input {
    color: #1887de;
    margin: 25px 0 120px;
    height: 40px;
    font-size: 18px;
    min-width: 200px;
    background-color: #fff;
    border: 1px solid #60b2ea;
    border-radius: 0;
  }
}

// Заявка на сайте

#request {
  h4 {
    display: inline-block;
  }
  .link {
    a {
      display: block;
      font-size: 18px;
      font-family: "NerisSemiBold", sans-serif;
      margin: 15px 0;
    }
    margin-bottom: 65px
  }

  p {
    margin-bottom: 60px;
  }

}

//FORM
h2 {
  font-family: "NerisLight", sans-serif;
  text-transform: uppercase;
  text-align: center;
  margin: 25px 0 70px;
  font-size: 5.6rem;
}

#content {

  .btn {
    background: #67bffb;
    color: #fff;
    font-size: 16px;
    font-family: "NerisThin", sans-serif;
    text-align: center;
    border-radius: 0;
    padding: 10px 20px;
    height: auto;
    text-transform: uppercase;
    &:hover {
      background: #1887de;
      color: #fff;
    }
  }
  .contact-input {
    text-align: center;
  }
  .join {
    li {
      float: none;
      display: inline-block;
    }
    margin: 0px 15px 100px;
  }
  .interest {
    .btn {
      margin-top: 15px;
    }
  }
}

// NEWS

#content {
  .project {
    span {
      padding: 0;
    }
    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      li {
        display: inline-block;
        background-color: #f0f2f4;
        margin: 15px 10px 10px 0;
        a {
          color: black;
        }
      }
    }
  }
}

.upload-file {
  color: #67bffb;
}

.upload-file:hover {
  color: #1887de;
}

.captcha {
  position: relative;
}

.captcha-border {
  position: absolute;
  top: -1px;
  left: -1px;
  width: 304px;
  height: 78px;
  z-index: 0;
  border: 1px solid #a94442;
  border-radius: 4px;
}

.tags-date {
  margin: 0;
  padding: 0;
  text-align: left;
  & > li, & > span {
    display: inline-block;
    background-color: #f0f2f4;
    margin: 5px 10px 5px 0;
    font-size: 12px;
    font-family: $fontOS;
    font-weight: 300;
    line-height: 20px;
    padding: 0 10px;
    & > a {
      font-size: 12px;
      color: $color-black;
    }
  }
}

.head-sm, h2.head-sm {
  font-size: 3.8rem;
  font-family: $fontNL;
  margin-bottom: 30px;
}